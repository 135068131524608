import { fetchConToken } from '../helpers/fetch';

export const updateClupikMetricTable = async () => {
	try {
		await fetchConToken(`clupik/update-metricas-rows`, 'GET');
	} catch (error) {
		console.log(error);
	}
};

export const updateLostDeals = async () => {
	try {
		await fetchConToken(`business/clientes/deals/update-lost`, 'GET');
	} catch (error) {
		console.log(error);
	}
};

export const updateOrganizationData = async () => {
	await fetchConToken(`business/clientes/set-in-deals`, 'GET');
};

export const updatePoints = async () => {
	try {
		await fetchConToken('pipe', {}, 'POST');
	} catch (error) {
		console.log(error);
	}
};
