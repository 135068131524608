import dayjs from 'dayjs';
import { fetchConToken } from '../helpers/fetch';

export const getClients = async () => {
	try {
		const resp = await fetchConToken(`business/clientes`, 'GET');
		const body = await resp.json();
		if (body.ok) {
			return body.clients;
		} else {
			return false;
		}
	} catch (error) {}
};

export const getClientsCount = async (start, amount, interval) => {
	const theInterval = interval || 'month';
	const setStart = start || dayjs().format('YYYY-MM-DD');
	const setAmount = amount + 1 || 1;

	const resp = await fetchConToken(`business/clientes/deals?start=${setStart}&interval=${theInterval}&amount=${setAmount}`, 'GET');
	const body = await resp.json();
	if (body.ok) {
		return body.totals;
	} else {
		const errorMsg = body.msg;
		console.log(errorMsg);
	}
};
