import React, { useEffect, useRef, useState } from 'react';
import { clubTraining } from '../../../../../actions/club.action';
import Box from '../../../../_ui/Box/Box';
import Spinner from '../../../../_ui/Spinner/Spinner';

const ClubTrainings = ({ points, clubId, season }) => {
    const isMounted = useRef(false);
    const [loading, setLoading] = useState(false);
    const [training, setTraining] = useState({});

    useEffect(() => {
        isMounted.current = true;
        setLoading(true);
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const getTrainings = async () => {
            setTraining({});
            let data = await clubTraining(clubId, season.id);
            if (data) {
                setTraining(data.data);
            }
            setLoading(false);
        };

        if (isMounted.current) {
            clubId && getTrainings();
        }
    }, []);

    return (
        <Box subtitle={ 'Últimos 30 días' } title={ 'Entrenamientos' } id="club-trainings" points={ points }>
            {
                loading ? (
                    <Spinner />
                ) : (
                    <>
                        <div className="preview">
                            <div className="each">
                                <h6 className="h6-14-b">Total</h6>
                                <p className="p4-14">{ training?.training } sesiones</p>
                            </div>
                            <div className="each">
                                <h6 className="h6-14-b">Toma de asistencia</h6>
                                <p className="p4-14">{ training?.training } recibidas - { training?.attendance } cerradas</p>
                            </div>
                            <div className="each">
                                <h6 className="h6-14-b">Lesiones (últimos 90 días)</h6>
                                <p className="p4-14">{ training?.injuries?.count } lesiones registradas</p>
                            </div>

                        </div>
                    </>
                )

            }
        </Box>
    );
};

export default React.memo(ClubTrainings);